import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import '../styles.css';
import educationImage from '../images/education.jpeg'; // Default fallback image

interface Program {
  id: number;
  name: string;
  location: string;
  description: string;
  imageUrl?: string;
  profile_link?: string;
  additional_info?: string;
}

interface RelatedItem {
  facility_name?: string; // For healthcare items
  Name?: string; // For legal items
  objectid?: number; // For legal items
  id?: number; // For educational items
  city: string;
  type: 'healthcare' | 'legal';
}

const ProgramDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [program, setProgram] = useState<Program | null>(null);
  const [relatedHealthcare, setRelatedHealthcare] = useState<RelatedItem[]>([]);
  const [relatedLegal, setRelatedLegal] = useState<RelatedItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [image, setImage] = useState<string>(educationImage); // Default to fallback image

  // New state for search and filter
  const [healthcareSearchTerm, setHealthcareSearchTerm] = useState<string>('');
  const [legalSearchTerm, setLegalSearchTerm] = useState<string>('');
  const [healthcareCityFilter, setHealthcareCityFilter] = useState<string>('');
  const [legalCityFilter, setLegalCityFilter] = useState<string>('');

  // Filtered related services
  const filteredHealthcare = relatedHealthcare.filter(item => 
    (healthcareSearchTerm === '' || 
      item.facility_name?.toLowerCase().includes(healthcareSearchTerm.toLowerCase())) &&
    (healthcareCityFilter === '' || item.city.toLowerCase().includes(healthcareCityFilter.toLowerCase()))
  );

  const filteredLegal = relatedLegal.filter(item => 
    (legalSearchTerm === '' || 
      item.Name?.toLowerCase().includes(legalSearchTerm.toLowerCase())) &&
    (legalCityFilter === '' || item.city.toLowerCase().includes(legalCityFilter.toLowerCase()))
  );

  useEffect(() => {
    const fetchProgramDetails = async () => {
      try {
        const response = await axios.get(
          `https://immigraid-backend.immigraid.me/api/educational-data/${id}`
        );
        const programData = response.data;
        setProgram(programData);

        // Fetch image for the program
        fetchImage(programData.name);

        // Fetch related services
        fetchRelatedHealthcare();
        fetchRelatedLegal();
      } catch (err) {
        console.error('Failed to fetch program details:', err);
        setError('Failed to fetch program details.');
      } finally {
        setLoading(false);
      }
    };

    fetchProgramDetails();
  }, [id]);

  const fetchImage = async (programName: string) => {
    try {
      const response = await fetch(
        `https://api.unsplash.com/search/photos?query=${encodeURIComponent(
          programName
        )}&client_id=${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const imageUrl = data.results[0].urls.regular; // Get the first image result
        setImage(imageUrl);
      } else {
        console.warn(`No image found for ${programName}. Using default.`);
        setImage(educationImage); // Fallback to default image
      }
    } catch (err) {
      console.error(`Error fetching image for ${programName}:`, err);
      setImage(educationImage); // Fallback to default image
    }
  };

  const fetchRelatedHealthcare = () => {
    axios
      .get(`https://immigraid-backend.immigraid.me/api/related-healthcare`)
      .then((response) => {
        // Get unique cities for filter dropdown
        const uniqueHealthcareCities = [...new Set(response.data.map((item: RelatedItem) => item.city))];
        setRelatedHealthcare(response.data.slice(0, 3));
      })
      .catch((error) => {
        console.error('Error fetching related healthcare:', error);
      });
  };

  const fetchRelatedLegal = () => {
    axios
      .get(`https://immigraid-backend.immigraid.me/api/related-legal`)
      .then((response) => {
        // Get unique cities for filter dropdown
        const uniqueLegalCities = [...new Set(response.data.map((item: RelatedItem) => item.city))];
        setRelatedLegal(response.data.slice(0, 3));
      })
      .catch((error) => {
        console.error('Error fetching related legal:', error);
      });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!program) {
    return <div>No program found.</div>;
  }

  const mapUrl = `https://maps.google.com/maps?q=${encodeURIComponent(
    program.location
  )}&t=&z=13&ie=UTF8&iwloc=&output=embed`;

  return (
    <div className="container mt-5">
      <h1>{program.name}</h1>
      <p><strong>Location:</strong> {program.location}</p>
      <p><strong>Description:</strong> {program.description}</p>
      {program.additional_info && (
        <p><strong>Additional Info:</strong> {program.additional_info}</p>
      )}

      {/* Image Section */}
      <section className="mb-4">
        <h2>Image</h2>
        <div style={{ width: '100%', maxHeight: '400px', overflow: 'hidden' }}>
          <img
            src={image}
            alt={program.name}
            style={{ width: '100%', height: '400px', objectFit: 'cover' }}
          />
        </div>
      </section>

      {/* Map Section */}
      <section className="mb-4">
        <h2>Map</h2>
        <div style={{ width: '100%', height: '500px' }}>
          <iframe
            src={mapUrl}
            style={{ border: 0, width: '100%', height: '100%' }}
            allowFullScreen
            loading="lazy"
            title="Program Location"
          />
        </div>
      </section>

      {/* Related Legal Services */}
      <section className="mb-4">
        <h2>Related Legal Services</h2>
        
        {/* Search and Filter for Legal Services */}
        <div className="row mb-3">
          <div className="col-md-6">
            <input 
              type="text" 
              className="form-control" 
              placeholder="Search Legal Services" 
              value={legalSearchTerm}
              onChange={(e) => setLegalSearchTerm(e.target.value)}
            />
          </div>
          <div className="col-md-6">
            <select 
              className="form-control"
              value={legalCityFilter}
              onChange={(e) => setLegalCityFilter(e.target.value)}
            >
              <option value="">All Cities</option>
              {[...new Set(relatedLegal.map(item => item.city))].map(city => (
                <option key={city} value={city}>{city}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="d-flex flex-row justify-content-start">
          {filteredLegal.map((legal, index) => (
            <div key={index} className="card" style={{ width: '18rem', margin: '10px' }}>
              <div className="card-body">
                <h5 className="card-title">{legal.Name}</h5>
                <p className="card-text">{legal.city}</p>
                <Link to={`/legalservices/${legal.Name}`}>View Details</Link>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Related Healthcare Services */}
      <section className="mb-4">
        <h2>Related Healthcare Services</h2>
        
        {/* Search and Filter for Healthcare Services */}
        <div className="row mb-3">
          <div className="col-md-6">
            <input 
              type="text" 
              className="form-control" 
              placeholder="Search Healthcare Services" 
              value={healthcareSearchTerm}
              onChange={(e) => setHealthcareSearchTerm(e.target.value)}
            />
          </div>
          <div className="col-md-6">
            <select 
              className="form-control"
              value={healthcareCityFilter}
              onChange={(e) => setHealthcareCityFilter(e.target.value)}
            >
              <option value="">All Cities</option>
              {[...new Set(relatedHealthcare.map(item => item.city))].map(city => (
                <option key={city} value={city}>{city}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="d-flex flex-row justify-content-start">
          {filteredHealthcare.map((item, index) => (
            <div key={index} className="card" style={{ width: '18rem', margin: '10px' }}>
              <div className="card-body">
                <h5 className="card-title">{item.facility_name}</h5>
                <p className="card-text">{item.city}</p>
                <Link to={`/healthcare/${encodeURIComponent(item.facility_name || '')}`}>View Details</Link>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default ProgramDetail;