import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import HealthcareImage from '../images/medical.jpg';
import axios from 'axios';

interface HealthcareFacility {
    facility_name: string;
    street_address: string;
    city: string;
    state: string;
    zip_code: string;
    phone_number: string;
    website_url: string;
}

interface EducationalProgram {
    id: number;
    name: string;
    location: string;
    description: string;
}

interface LegalService {
    objectid: number;
    Name: string;
    city: string;
    description: string;
}

interface RelatedItem {
    name?: string; // For healthcare items
    Name?: string; // For legal items
    objectid?: number; // For legal items
    id?: number; // For educational items
    city?: string;
    location?: string;
    description: string;
    type: 'healthcare' | 'legal';
}

const HealthcareDetail: React.FC = () => {
    const { facilityName } = useParams<{ facilityName?: string }>();
    const [healthcareData, setHealthcareData] = useState<HealthcareFacility | null>(null);
    const [relatedEducation, setRelatedEducation] = useState<RelatedItem[]>([]);
    const [relatedLegal, setRelatedLegal] = useState<RelatedItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [image, setImage] = useState<string>(HealthcareImage);

    useEffect(() => {
        document.title = "Healthcare Programs";
        setLoading(true);
        setError(null);

        if (facilityName) {
            fetch(`https://immigraid-backend.immigraid.me/api/healthcare-data?name=${encodeURIComponent(facilityName)}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    // Handle the array response and take the first matching facility
                    if (Array.isArray(data) && data.length > 0) {
                        setHealthcareData(data[0]);
                        fetchRelatedEducation();
                        fetchRelatedLegal();
                    } else {
                        throw new Error('Facility not found');
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Error fetching healthcare data:", error);
                    setError(error.message || 'Error fetching healthcare data');
                    setLoading(false);
                });
        } else {
            setError('Facility name is missing.');
            setLoading(false);
        }
    }, [facilityName]);

    const fetchImage = async (facilityName: string) => {
        try {
            const response = await fetch(
                `https://api.unsplash.com/search/photos?query=${encodeURIComponent(
                    facilityName
                )}&client_id=${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`
            );
            const data = await response.json();
            if (data.results && data.results.length > 0) {
                setImage(data.results[0].urls.regular);
            }
        } catch (err) {
            console.error(`Error fetching image:`, err);
            // Keep default image on error
        }
    };

    useEffect(() => {
        if (facilityName) {
            fetchImage(facilityName);
        }
    }, [facilityName]);

    const fetchRelatedEducation = () => {
        fetch(`https://immigraid-backend.immigraid.me/api/related-education`)
            .then((response) => response.json())
            .then((data) => setRelatedEducation(data.slice(0, 3))) // Limit to 3 items
            .catch((error) => console.error("Error fetching related education:", error));
    };

    const fetchRelatedLegal = () => {
        axios.get(`https://immigraid-backend.immigraid.me/api/related-legal`)
            .then(response => {
                setRelatedLegal(response.data.slice(0, 3)); // Limit to 3 related items
            })
            .catch(error => {
                console.error('Error fetching related legal:', error);
            });
    };

    if (loading) {
        return <div className="container mt-5">Loading healthcare data...</div>;
    }

    if (error) {
        return <div className="container mt-5">Error: {error}</div>;
    }

    if (!healthcareData) {
        return <div className="container mt-5">No healthcare data found.</div>;
    }

    const { facility_name, street_address, city, state, zip_code, phone_number, website_url } = healthcareData;
    const address = `${street_address || ''}, ${city || ''}, ${state || ''}, ${zip_code || ''}`;
    const mapUrl = `https://maps.google.com/maps?q=${encodeURIComponent(address)}&t=&z=13&ie=UTF8&iwloc=&output=embed`;

    return (
        <div className="container mt-5">
            <h1>{facility_name || 'No Facility Name'}</h1>
            <p><strong>Address:</strong> {address}</p>
            <p><strong>Phone:</strong> {phone_number || 'Not Provided'}</p>
            <p><strong>Website:</strong> <a href={website_url || '#'}>{website_url || 'Not Provided'}</a></p>

            {/* Image Section */}
            <section className="mb-4">
                <h2>Image</h2>
                <div style={{ width: '100%', maxHeight: '400px', overflow: 'hidden' }}>
                    <img
                        src={image}
                        alt={facility_name || 'Healthcare Facility'}
                        style={{ width: '100%', height: '400px', objectFit: 'cover' }}
                    />
                </div>
            </section>

            {/* Map Section */}
            <section className="mb-4">
                <h2>Map</h2>
                <div style={{ width: '100%', height: '500px' }}>
                    <iframe
                        src={mapUrl}
                        style={{ border: 0, width: '100%', height: '100%' }}
                        allowFullScreen
                        loading="lazy"
                        title="Healthcare Facility Location">
                    </iframe>
                </div>
            </section>

            {/* Related Educational Programs */}
            <section className="mb-4">
                <h2>Related Educational Programs</h2>
                <div className="d-flex flex-row justify-content-start">
                    {relatedEducation.map((program, index) => (
                        <div key={index} className="card" style={{ width: '18rem', margin: '10px' }}>
                            <div className="card-body">
                                <h5 className="card-title">{program.name}</h5>
                                <p className="card-text">{program.location}</p>
                                <Link to={`/programs/${program.id}`}>View Details</Link>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Related Legal Services */}
            <section className="mb-4">
                <h2>Related Legal Services</h2>
                <div className="d-flex flex-row justify-content-start">
                    {relatedLegal.map((legal, index) => (
                        <div key={index} className="card" style={{ width: '18rem', margin: '10px' }}>
                            <div className="card-body">
                                <h5 className="card-title">{legal.Name}</h5>
                                <p className="card-text">{legal.city}</p>
                                <Link to={`/legalservices/${legal.Name}`}>View Details</Link>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default HealthcareDetail;