import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import mortarboardIcon from '../icons/mortarboard.svg';
import heartPulseIcon from '../icons/heart-pulse.svg';
import searchIcon from '../icons/search.svg';

const Navbar: React.FC = () => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSearch = () => {
    if (searchInput.trim() !== '') {
      navigate(`/search?query=${encodeURIComponent(searchInput)}`);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <Link className="navbar-brand ms-2" to="/">
          ImmigriAid
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleMenu}
          aria-controls="navbarNav"
          aria-expanded={isMenuOpen}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarNav">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/EducationalPrograms">
                <img
                  src={mortarboardIcon}
                  alt="Education Icon"
                  style={{ width: '16px', height: '16px', marginRight: '8px' }}
                />
                Education
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/healthcare">
                <img
                  src={heartPulseIcon}
                  alt="Healthcare Icon"
                  style={{ width: '16px', height: '16px', marginRight: '8px' }}
                />
                Healthcare
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/legalservices">
                <img
                  src={searchIcon}
                  alt="Legal Icon"
                  style={{ width: '16px', height: '16px', marginRight: '8px' }}
                />
                Legal
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">
                About
              </Link>
            </li>
            <li className="nav-item">
            <Link className="nav-link" to="/datavisualization">Our Visualizations</Link>
            </li>
            <li className="nav-item">
            <Link className="nav-link" to="/developervisualization">Developer Visualizations</Link>
            </li>
          </ul>
          <div className="d-flex flex-column flex-lg-row mt-3 mt-lg-0">
            <input
              type="text"
              className="form-control me-2 mb-2 mb-lg-0"
              placeholder="Search..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyDown={handleKeyDown}
              style={{ width: '200px' }}
            />
            <button className="btn btn-primary" onClick={handleSearch}>
              Search
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;