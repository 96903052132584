import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from 'recharts';

const LegalPieChart: React.FC = () => {
  const [data, setData] = useState<{ city: string; count: number }[]>([]);

  useEffect(() => {
    // Fetch the data from your backend API
    fetch('https://immigraid-backend.immigraid.me/api/legal-aggregated')
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error('Error fetching legal data:', error));
  }, []);

  // Define a color palette
  const COLORS = [
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#A28DFC',
    '#FF679B',
    '#D2A458',
    '#9EE5A1',
  ];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '450px',
      }}
    >
      <h3 style={{ textAlign: 'center', marginBottom: '10px' }}>
      </h3>
      {data.length ? (
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data}
              dataKey="count"
              nameKey="city"
              cx="50%"
              cy="50%"
              outerRadius={180}
              fill="#8884d8"
              label
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip
              formatter={(value: number, name: string) => [
                `${value} services`,
                `City: ${name}`,
              ]}
            />
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <p>Loading data...</p>
      )}
    </div>
  );
};

export default LegalPieChart;
