// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import About from './components/About';
import Model2 from './components/model2';
import Model3 from './components/model3';
import Home from './components/Home';
import HealthcarePage from './components/Healthcare';
import HealthcareDetailed from './components/HealthcareDetailed';
import LegalServiceDetail from './components/LegalServicesDetail';
import EducationalPrograms from './components/EducationalPrograms'; // Updated import
import ProgramDetail from './components/ProgramDetail';
import LegalServices from './components/Legalservices';
import SearchResults from './components/SearchResults';
import OurVisualizationPage from './components/OurVisualizationPage';
import DeveloperVisualizationPage from './components/DeveloperVisualization';
import './styles.css';

const App = () => {
  const [programs, setPrograms] = useState([]);

  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/model2" element={<Model2 />} />
          <Route path="/model3" element={<Model3 />} />
          <Route path="/healthcare" element={<HealthcarePage />} />
          <Route path="/healthcare/:facilityName" element={<HealthcareDetailed />} />{/*Route for Detailed Healthcare */}
          <Route path="/EducationalPrograms" element={<EducationalPrograms />} />
          <Route path="/legalservices" element={<LegalServices />} />
          <Route path="/legalservices/:Name" element={<LegalServiceDetail />} />{/*Route for Detailed Legal Service */}
          <Route path="/programs/:id" element={<ProgramDetail />} /> {/* Update this route */}
          <Route path="/" element={<Home />} />
          <Route path="*" element={<h2>404 Not Found</h2>} />
          <Route path="/search" element={<SearchResults />} />
          <Route path="/datavisualization" element={<OurVisualizationPage />} />
          <Route path="/developervisualization" element={<DeveloperVisualizationPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
