import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../styles.css';
import EducationImage from '../images/educationCard.jpeg'; // Default image
import Highlight from './Highlight';

interface Program {
  id: number;
  name: string;
  location: string; // Contains city, state, and country
  description: string;
  imageUrl?: string;
  additional_content?: string;
}

interface SearchSuggestion {
  text: string;
  type: 'name' | 'location';
  category: string;
}

const EducationalPrograms: React.FC = () => {
  const [programs, setPrograms] = useState<Program[]>([]);
  const [filteredPrograms, setFilteredPrograms] = useState<Program[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(5);
  const [pageInput, setPageInput] = useState<string>('');
  const [images, setImages] = useState<{ [key: number]: string }>({});
  const [searchInput, setSearchInput] = useState<string>(''); // Search input
  const [filterState, setFilterState] = useState<string>(''); // State filter
  const [filterCity, setFilterCity] = useState<string>(''); // City filter
  const [isReversed, setIsReversed] = useState<boolean>(false);
  const [isReversedCity, setIsReversedCity] = useState<string>("none");
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<SearchSuggestion[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const searchContainerRef = useRef<HTMLDivElement>(null);


  const defaultImageUrl = EducationImage; // Default fallback image


  useEffect(() => {
    document.title = "Educational Programs";
    // Add click event listener to close suggestions when clicking outside
    const handleClickOutside = (event: MouseEvent) => {
      if (searchContainerRef.current && !searchContainerRef.current.contains(event.target as Node)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);


  useEffect(() => {
    document.title = "Educational Programs";
    const fetchSuggestions = async () => {
      if (searchInput.length >= 2) {
        try {
          const response = await fetch(`https://immigraid-backend.immigraid.me/api/search-suggestions?prefix=${encodeURIComponent(searchInput)}&category=educational`);
          if (response.ok) {
            const data = await response.json();
            setSuggestions(data);
            setShowSuggestions(true);
          }
        } catch (error) {
          console.error('Error fetching suggestions:', error);
        }
      } else {
        setSuggestions([]);
        setShowSuggestions(false);
      }
    };

    const debounceTimer = setTimeout(() => {
      fetchSuggestions();
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [searchInput]);


  useEffect(() => {
    document.title = 'Educational Programs';

    fetch('https://immigraid-backend.immigraid.me/api/educational-data')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setPrograms(data);
          setFilteredPrograms(data);
          setLoading(false);
        } else {
          console.error('Unexpected data format:', data);
          setError('Unexpected data format received.');
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error loading programs:', error);
        setError('Error loading programs');
        setLoading(false);
      });
  }, []);

  // Fetch images for programs from Unsplash
  useEffect(() => {
    const fetchImages = async () => {
      const fetchImageForProgram = async (program: Program) => {
        try {
          const response = await fetch(
            `https://api.unsplash.com/search/photos?query=${program.name}&client_id=${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`
          );
          const data = await response.json();
          const imageUrl = data.results[0]?.urls?.regular || defaultImageUrl;
          setImages((prev) => ({ ...prev, [program.id]: imageUrl }));
        } catch (error) {
          console.error(`Error fetching image for ${program.name}:`, error);
          setImages((prev) => ({ ...prev, [program.id]: defaultImageUrl }));
        }
      };

      programs.forEach(fetchImageForProgram);
    };

    if (programs.length > 0) {
      fetchImages();
    }
  }, [programs]);

  // Extract city and state from the location field
  const extractLocation = (location: string) => {
    const [city = '', state = ''] = location.split(',').map((part) => part.trim());
    return { city, state };
  };

  const handleSearch = () => {
    let updatedPrograms = programs;

    // Apply search filter
    if (searchInput) {
      updatedPrograms = updatedPrograms.filter(
        (program) =>
          program.name.toLowerCase().includes(searchInput.toLowerCase()) ||
          program.description.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    // Apply state filter
    if (filterState) {
      updatedPrograms = updatedPrograms.filter(
        (program) =>
          extractLocation(program.location).state.toLowerCase() === filterState.toLowerCase()
      );
    }

    // Apply city filter
    if (filterCity) {
      updatedPrograms = updatedPrograms.filter(
        (program) =>
          extractLocation(program.location).city.toLowerCase() === filterCity.toLowerCase()
      );
    }

    if (isReversedCity === "reverse") {
      updatedPrograms = updatedPrograms.sort((a, b) => {
        if (extractLocation(a.location).city > extractLocation(b.location).city) return -1;
        if (extractLocation(a.location).city < extractLocation(b.location).city) return 1;
        return 0;
      });
    } else if (isReversedCity === "order") {
      updatedPrograms = updatedPrograms.sort((a, b) => {
        if (extractLocation(a.location).city < extractLocation(b.location).city) return -1;
        if (extractLocation(a.location).city > extractLocation(b.location).city) return 1;
        return 0;
      });
    }

    if (isReversed) {
      setIsReversedCity('none')
      updatedPrograms = updatedPrograms.sort((a, b) => {
        if (a.name > b.name) return -1;
        if (a.name < b.name) return 1;
        return 0;
      });
    }

    setFilteredPrograms(updatedPrograms);
    setIsSearchActive(true);

    // If no results are found, show an error message
    if (updatedPrograms.length === 0) {
      setError('No programs match your search criteria.');
    } else {
      setError(null);
    }

    setCurrentPage(1); // Reset to the first page after filtering
  };

  const clearFilters = () => {
    setSearchInput('');
    setFilterState('');
    setFilterCity('');
    setIsReversedCity('none');
    setIsReversed(false);
    setFilteredPrograms(programs.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    })); // Reset to all services
    setError(null);
    setIsSearchActive(false);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(filteredPrograms.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredPrograms.slice(indexOfFirstItem, indexOfLastItem);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const handleSuggestionClick = (suggestion: SearchSuggestion) => {
    setSearchInput(suggestion.text);
    setSearchQuery(suggestion.text);
    setShowSuggestions(false);
    setCurrentPage(1);
  };
  const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInput(e.target.value);
  };

  const goToPage = () => {
    const pageNumber = parseInt(pageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setPageInput('');
    } else {
      alert(`Please enter a valid page number between 1 and ${totalPages}`);
    }
  };

  if (loading) {
    return <p>Loading educational programs...</p>;
  }

  if (error && isSearchActive) {
    return <p>{error}</p>;
  }

  if (filteredPrograms.length === 0 && isSearchActive) {
    return <p>No programs available at the moment.</p>;
  }

  return (
    <div style={{ background: 'linear-gradient(to right, #001f3d, #4b7fb3)', minHeight: '100vh', color: 'white' }}>
    <div className="container">
      <h1>Educational Programs</h1>

      {/* Search and Filter Section */}
      <div
        style={{
          marginBottom: '20px',
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <div style={{ flex: 1, position: 'relative' }}>
          <input
            type="text"
            placeholder="Search programs..."
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
            style={{ padding: '8px', width: '100%' }}
          />
          {/* Suggestions Dropdown */}
          {showSuggestions && suggestions.length > 0 && (
            <div
              style={{
                position: 'absolute',
                top: '100%',
                left: 0,
                right: 0,
                background: '#1a365d',
                border: '1px solid #2d4a77',
                borderRadius: '4px',
                boxShadow: '0 4px 6px rgba(0,0,0,0.3)',
                zIndex: 1000,
                marginTop: '4px',
              }}
            >
              {suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  style={{
                    padding: '12px 16px',
                    cursor: 'pointer',
                    borderBottom: index < suggestions.length - 1 ? '1px solid #2d4a77' : 'none',
                    display: 'flex',
                    alignItems: 'center',
                    transition: 'background-color 0.2s ease',
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = '#2d4a77';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = 'transparent';
                  }}
                >
                  <span
                    style={{
                      marginRight: '12px',
                      color: suggestion.type === 'name' ? '#60a5fa' : '#34d399'
                    }}
                  >
                    {suggestion.type === 'name' ? '🏥' : '📍'}
                  </span>
                  <span style={{ color: '#ffffff' }}>{suggestion.text}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        <input
          type="text"
          placeholder="Filter by state..."
          value={filterState}
          onChange={(e) => setFilterState(e.target.value)}
          style={{ padding: '8px', width: '150px' }}
        />
        <input
          type="text"
          placeholder="Filter by city..."
          value={filterCity}
          onChange={(e) => setFilterCity(e.target.value)}
          style={{ padding: '8px', width: '150px' }}
        />

        <label>
          <input
            name="reverse-alphabetical"
            type="checkbox"
            checked={isReversed}
            onChange={(e) => setIsReversed(e.target.checked)}
          />
          Reverse Alphabetical
        </label>
        <label>
          Sort City By:
          <select
            value={isReversedCity}
            onChange={(e) => setIsReversedCity(e.target.value)}
            style={{ padding: '8px', marginLeft: '10px' }}
          >
          <option value="none">None</option>
          <option value="order">Alphabetically</option>
          <option value="reverse">Reverse</option>
          </select>
        </label>
        

        <button onClick={handleSearch} style={{ padding: '8px 16px' }}>
          Search
        </button>
        <button onClick={clearFilters} style={{ padding: '8px 16px' }}>
          Clear Filters
        </button>
      </div>

      {/* Programs List */}
      <div style={cardContainerStyle}>
        <div className="row" id="instances-container">
          {currentItems.map((program) => (
            <div className="col-md-4 instance-card mb-4" key={program.id}>
              <Link to={`/programs/${program.id}`} className="text-decoration-none">
                <div className="card" style={educationCardStyle}>
                  <img
                    src={images[program.id] || defaultImageUrl}
                    className="card-img-top"
                    alt={program.name}
                    style={{ height: '200px', objectFit: 'cover' }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      <Highlight text={program.name} searchTerm={searchInput || ''} />
                    </h5>
                    <p className="card-text">
                      Location:{' '}
                      <Highlight text={program.location} searchTerm={searchInput || ''} />
                    </p>
                    <p style={descriptionStyle} className="card-text">
                      Description:{' '}
                      <Highlight text={program.description} searchTerm={searchInput || ''} />
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="pagination-controls" style={paginationStyle}>
        <button onClick={prevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={nextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
      <div style={jumpToPageStyle}>
        <input
          type="number"
          value={pageInput}
          onChange={handlePageInputChange}
          placeholder={String(currentPage)}
          min="1"
          max={totalPages}
          style={pageInputStyle}
        />
        <button onClick={goToPage} style={{ padding: '5px 10px' }}>
          Go
        </button>
      </div>
    </div>
    <footer className="text-center mt-5">
      <p style={{ color: 'white' }}>&copy; 2024 Our Site</p>
    </footer>
    </div>
  );
};

const educationCardStyle: React.CSSProperties = {
  border: '1px solid #ddd',
  padding: '16px',
  borderRadius: '8px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  margin: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '450px',
};

const descriptionStyle: React.CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  whiteSpace: 'normal',
};

const cardContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
  marginTop: '20px',
};

const paginationStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '20px',
};

const dropdownStyle: React.CSSProperties = {
  marginBottom: '20px',
};

const jumpToPageStyle: React.CSSProperties = {
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const pageInputStyle: React.CSSProperties = {
  marginRight: '10px',
  padding: '5px',
  width: '60px',
};

export default EducationalPrograms;
