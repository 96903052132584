import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom'; // Added Link to fix previous error

interface SearchResult {
  id: number;
  name: string;
  type: string; // Model type (e.g., Education, Healthcare, Legal)
  description: string;
  link: string; // URL to the detailed page
}

const SearchResults: React.FC = () => {
  const location = useLocation();
  const [query, setQuery] = useState<string>('');
  const [results, setResults] = useState<SearchResult[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Extract query parameter from the URL
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get('query') || '';
    setQuery(searchQuery);

    if (searchQuery) {
      fetchSearchResults(searchQuery);
    } else {
      setResults([]);
      setLoading(false);
    }
  }, [location.search]);

  const fetchSearchResults = async (searchQuery: string) => {
    try {
      setLoading(true);
      setError(null);

      // Fetch data for all models
      const [educationRes, healthcareRes, legalRes] = await Promise.all([
        fetch(`https://immigraid-backend.immigraid.me/api/educational-data`),
        fetch(`https://immigraid-backend.immigraid.me/api/healthcare-data`),
        fetch(`https://immigraid-backend.immigraid.me/api/legal-services-data`),
      ]);

      if (!educationRes.ok || !healthcareRes.ok || !legalRes.ok) {
        throw new Error('Failed to fetch search results');
      }

      const [educationData, healthcareData, legalData] = await Promise.all([
        educationRes.json(),
        healthcareRes.json(),
        legalRes.json(),
      ]);

      // Combine and filter results based on the query
      const combinedResults: SearchResult[] = [
        ...educationData
          .filter((item: any) =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
          )
          .map((item: any) => ({
            id: item.id,
            name: item.name,
            type: 'Education',
            description: item.description,
            link: `/programs/${item.id}`,
          })),
        ...healthcareData
          .filter((item: any) =>
            item.facility_name.toLowerCase().includes(searchQuery.toLowerCase())
          )
          .map((item: any) => ({
            id: item.id,
            name: item.facility_name,
            type: 'Healthcare',
            description: item.street_address,
            link: `/healthcare/${encodeURIComponent(item.facility_name)}`,
          })),
        ...legalData
          .filter((item: any) =>
            item.Name.toLowerCase().includes(searchQuery.toLowerCase())
          )
          .map((item: any) => ({
            id: item.id,
            name: item.Name,
            type: 'Legal',
            description: item.description,
            link: `/legalservices/${encodeURIComponent(item.Name)}`,
          })),
      ];

      setResults(combinedResults);
    } catch (err) {
      console.error(err);
      setError('Error fetching search results.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <p>Loading search results...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (results.length === 0) {
    return <p>No results found for "{query}".</p>;
  }

  return (
    <div className="container">
      <h1>Search Results for "{query}"</h1>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
        {results.map((result) => (
          <div
            key={result.id}
            style={{
              border: '1px solid #ddd',
              borderRadius: '8px',
              padding: '16px',
              width: '300px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          >
            <h3>{result.name}</h3>
            <p>{result.description}</p>
            <p>
              <strong>Category:</strong> {result.type}
            </p>
            <Link to={result.link}>View Details</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchResults;
