import React from 'react';
import HealthcareBarChart from './HealthcareBarChart';
import LegalPieChart from './LegalPieChart';
import EducationalLineChart from './EducationalLineChart';
import FacilitiesBarChart from './FacilitiesBarChart'; 
import FormsGraph from './FormsGraph';
import JobsPayChart from './JobsPayChart';

const DeveloperVisualizationPage: React.FC = () => {

  const styles = {
    visualizationPageContainer: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      justifyContent: 'flex-start' as 'flex-start',
      alignItems: 'center' as 'center',
      height: 'auto', // Allow container to grow based on content height
      textAlign: 'center' as 'center',
      padding: '20px',
      color: '#333', // Dark text color for readability
      borderRadius: '8px', // Optional: rounded corners for a softer appearance
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Lighter shadow for a soft effect
    },
    content: {
      maxWidth: '1500px',
      width: '100%',
      padding: '20px',
      boxSizing: 'border-box' as 'border-box',
      backgroundColor: '#f9f9f9', // Light background for the content box
      color: '#333', // Dark text color for readability
      borderRadius: '8px', // Optional: rounded corners for a soft appearance
    },
    chartsContainer: {
      display: 'flex',
      flexWrap: 'wrap' as 'wrap', // This ensures that charts wrap to the next line on smaller screens
      justifyContent: 'space-between', // Ensure the charts on the same row are spaced evenly
      gap: '20px', // Adjust gap between charts
      alignItems: 'flex-start' as 'flex-start', // Align items at the start of the row
      marginBottom: '20px',
    },
    chart: {
      width: '100%',
      maxWidth: '600px', // Decrease max width to ensure charts fit
      textAlign: 'center' as 'center',
      marginBottom: '20px',
      color: '#333', // Dark text color for readability
      borderRadius: '8px', // Optional: rounded corners for each chart container
      padding: '20px', // Optional: add padding inside the chart to separate it from the shadow
    },
    // Adding a wrapper to center the second row of visualizations
    secondRowWrapper: {
      display: 'flex',
      justifyContent: 'center', // Center second row
      width: '100%',
      marginTop: '20px', // Adds space between the rows
    },
  };


  return (
    <div style={{ background: 'linear-gradient(to right, #001f3d, #4b7fb3)'}}>
    <div style={styles.visualizationPageContainer}>
      {/* <h1> Our Visualizations </h1> */}
      <div style={styles.content}>
      <h1> Developer Visualizations </h1>
      <div style={styles.chartsContainer}>
        <div style={styles.chart}>
        <h2>Number of Facilities by Type</h2>
        <FacilitiesBarChart /> 
        </div>
        <div style={styles.chart}>
        <h2>Number of Forms by Type</h2>
        <FormsGraph />
        </div>
      </div>
      {/* Second Row of Visualizations */}
      <div style={styles.secondRowWrapper}>
        <div style={styles.chart}>
        <h2>Jobs Pay type Distribution</h2>
        <JobsPayChart />
        </div>
      </div>
      <h1> Developer Critiques </h1>
      <p><strong>What did they do well?</strong></p>
      <p>Our developer group did a great job of organizing the instance cards on the model pages. They were extremely easy to navigate with very clear filters and search bars.</p>
      <p><strong>How effective was their RESTful API?</strong></p>
      <p>We initially had some issues figuring out which URL to use to access the API. However, once we figured that out, the API was relatively simple to use and provided meaningful analytics. The only other roadblock we ran into was that we were unable to locate and access their Postman documentation for the API.</p>
      <p><strong>How well did they implement your user stories?</strong></p>
      <p>They did a great job making small changes such as improving their navigation bar and adding more filters for city, state, and location type. However, they did struggle to complete two of the user stories from the last phase. We were able to work around this by incorporating those user stories into the most recent phase and expanding on them to fit the current phase.</p>
      <p><strong>What did we learn from their website?</strong></p>
      <p>From their website, we learned that veterans face a much higher unemployment rate in Texas than the rest of society. Their site helped us learn about the various facilities provided for veterans such as health care facilities and benefits centers. We also learned about various forms to help veterans gain benefits and services as well as potential job opportunities.</p>
      <p><strong>What can they do better?</strong></p>
      <p>The group could have done a better job incorporating more visual media. This could have been done in both the instance cards on the model pages and the actual instance pages themselves. I think it would have made the site more enticing to show pictures of the different veteran centers or videos about how to fill out certain veteran forms.</p>
      <p><strong>What puzzles us about their website?</strong></p>
      <p>One thing that puzzles our group about their website is the motivation for why they chose to cover information about veterans. They did a great job discussing how their website benefitted veterans and providing resources but could have expanded on what sparked their interest in the topic.</p>
    </div>
    </div>
    </div>
  );
};

export default DeveloperVisualizationPage;
