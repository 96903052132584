import React from 'react';
import HealthcareBarChart from './HealthcareBarChart';
import LegalPieChart from './LegalPieChart';
import EducationalLineChart from './EducationalLineChart';
import FacilitiesBarChart from './FacilitiesBarChart'; 
import FormsGraph from './FormsGraph';
import JobsPayChart from './JobsPayChart';

const OurVisualizationPage: React.FC = () => {

  const styles = {
    visualizationPageContainer: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      justifyContent: 'flex-start' as 'flex-start',
      alignItems: 'center' as 'center',
      height: 'auto', // Allow container to grow based on content height
      textAlign: 'center' as 'center',
      padding: '20px',
      color: '#f0f0f0', // Dark text color for readability
      borderRadius: '8px', // Optional: rounded corners for a softer appearance
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Lighter shadow for a soft effect
      
    },
    content: {
      maxWidth: '1500px',
      width: '100%',
      padding: '20px',
      boxSizing: 'border-box' as 'border-box',
      backgroundColor: '#f9f9f9', // Light background for the content box
      color: '#333', // Dark text color for readability
      borderRadius: '8px', // Optional: rounded corners for a soft appearance
    },
    chartsContainer: {
      display: 'flex',
      flexWrap: 'wrap' as 'wrap', // This ensures that charts wrap to the next line on smaller screens
      justifyContent: 'space-between', // Ensure the charts on the same row are spaced evenly
      gap: '20px', // Adjust gap between charts
      alignItems: 'flex-start' as 'flex-start', // Align items at the start of the row
      marginBottom: '20px',
    },
    chart: {
      width: '100%',
      maxWidth: '600px', // Decrease max width to ensure charts fit
      textAlign: 'center' as 'center',
      marginBottom: '20px',
      color: '#333', // Dark text color for readability
      borderRadius: '8px', // Optional: rounded corners for each chart container
      padding: '20px', // Optional: add padding inside the chart to separate it from the shadow
    },
    // Adding a wrapper to center the second row of visualizations
    secondRowWrapper: {
      display: 'flex',
      justifyContent: 'center', // Center second row
      width: '100%',
      marginTop: '20px', // Adds space between the rows
    },
  };
  

  return (
    <div style={{ background: 'linear-gradient(to right, #001f3d, #4b7fb3)'}}>
    <div style={styles.visualizationPageContainer}>
      {/* <h1> Our Visualizations </h1> */}
      <div style={styles.content}>
      <h1>Our Visualizations</h1>
      <div style={styles.chartsContainer}>
        <div style={styles.chart}>
          <h2>Healthcare Facilities by State</h2>
          <HealthcareBarChart />
        </div>
        <div style={styles.chart}>
          <h2>Legal Services by Cities in California</h2>
          <LegalPieChart />
        </div>
      </div>
      {/* Second Row of Visualizations */}
      <div style={styles.secondRowWrapper}>
        <div style={styles.chart}>
          <h2>Educational Programs by State</h2>
          <EducationalLineChart />
        </div>
      </div>
      <h1> Self Critiques </h1>
      <p><strong>What did we do well?</strong></p>
      <p>Our team communicated well and did a good job of delegating tasks to each other. Also, whenever one of us ran into issues or bugs that we needed advice on, the rest of the group acted as resources and were quick to offer tips and contribute to solving the problem.
      </p>
      <p><strong>What did we learn?</strong></p>
      <p>We each learned that working in a team requires us to depend on each other. With the amount of work that needed to be done in each phase, it was impossible for one or two people to shoulder all the work or for a person to get their share of work done all at once. Instead, a lot of our work depended on the work others were doing. This required a lot of cooperation and collaboration.</p>
      <p><strong>What did we teach each other?</strong></p>
      <p>We taught each other different technical skills such as how to implement our Selenium tests, endpoints for REST API, and automating pages for each of our instances. Beyond teaching each other about different skills to help implement our website, we also taught each other to be supportive and to hold each other accountable.</p>
      <p><strong>What can we do better?</strong></p>
      <p>I think we could have done a better job of starting the work for each phase earlier. We had to use our late days on two of the phases and definitely had the tendency of pushing a lot of the work until a few days before the deadline. I think if we got started earlier, we would have been less stressed and done a more thorough job.</p>
      <p><strong>What effect did the peer reviews have?</strong></p>
      <p>The peer reviews helped motivate us and point out what the other members of our group appreciated about our work. The reviews also helped us discover ways we could improve in the next phase. However, I think the most important effect of the peer reviews was getting the chance to share our appreciation for our other members.</p>
      <p><strong>What puzzles us?</strong></p>
      <p>At first, we had some troubles making sure our ideas met the constraints of the website. We also ran into issues determining all of the requirements for each phase. However, we were able to go over these roadblocks by consulting our fellow students and Abbhinav.</p>
    </div>
    </div>
    </div>
  );
};



export default OurVisualizationPage;
