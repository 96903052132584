import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// Define the interface for a single job object
interface Job {
  company_name: string;
  job_id: number;
  job_name: string;
  job_url: string;
  location: string;
  pay_description: string;
  pay_max: number;
  pay_min: number;
}

const JobsPayChart: React.FC = () => {
  const [data, setData] = useState<{ name: string; value: number }[]>([]); // Store the chart data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state

  // Fetch the job data when the component mounts
  useEffect(() => {
    fetch('https://api.vetassist.tech/employers') // Replace with your API endpoint
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((fetchedData) => {
        const payCounts: { [key: string]: number } = { 'per year': 0, 'per hour': 0 };
        // Group the jobs by pay_description and count how many jobs are per year vs per hour
        fetchedData.forEach((job: Job) => {
          const payType = job.pay_description.toLowerCase();
          if (payCounts[payType] !== undefined) {
            payCounts[payType]++;
          }
        });

        // Prepare the data for the Pie chart
        const chartData = [
          { name: 'Per Year', value: payCounts['per year'] || 0 },
          { name: 'Per Hour', value: payCounts['per hour'] || 0 },
        ];

        setData(chartData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError(`Failed to load data: ${error.message}`);
        setLoading(false);
      });
  }, []);

  // Loading state
  if (loading) {
    return <p>Loading...</p>;
  }

  // Error state
  if (error) {
    return <p>{error}</p>;
  }

  // No data available state
  if (data.length === 0) {
    return <p>No data available to display.</p>;
  }

  // Define color palette for the chart
  const COLORS = ['#8884d8', '#82ca9d'];

  return (
    <div>
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            outerRadius={120}
            innerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default JobsPayChart;

