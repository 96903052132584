import React, { useState, useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import LegalImage from '../images/legalCard.jpeg';
import Highlight from './Highlight'; // Import the Highlight component
import '../styles.css'; // Link to the external CSS file


interface LegalService {
  Name: string;
  addrln1: string;
  addrln2?: string;
  city: string;
  state: string;
  zip: string;
  phones: string;
  hours: string;
  description: string;
  url: string;
  link: string;
}

interface SearchSuggestion {
  text: string;
  type: 'Name' | 'addrln1';
  category: string;
}

const LegalServicesPage: React.FC = () => {
  const [legalData, setLegalData] = useState<LegalService[]>([]);
  const [filteredLegalData, setFilteredLegalData] = useState<LegalService[]>([]);
  const [images, setImages] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(5);
  const [pageInput, setPageInput] = useState<string>('');
  const [searchInput, setSearchInput] = useState<string>(''); // Search input
  const [filterState, setFilterState] = useState<string>(''); // State filter
  const [filterCity, setFilterCity] = useState<string>(''); // City filter
  const [filterPhone, setFilterPhone] = useState<string>(''); // Phone filter
  const [isReversed, setIsReversed] = useState<boolean>(false);
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false); // Tracks if search is active
  const [suggestions, setSuggestions] = useState<SearchSuggestion[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const searchContainerRef = useRef<HTMLDivElement>(null);

  const defaultImageUrl = LegalImage;

  useEffect(() => {
    document.title = "Legal Assistance";
    // Add click event listener to close suggestions when clicking outside
    const handleClickOutside = (event: MouseEvent) => {
      if (searchContainerRef.current && !searchContainerRef.current.contains(event.target as Node)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);


  useEffect(() => {
    document.title = "Legal Assistance";
    const fetchSuggestions = async () => {
      if (searchInput.length >= 2) {
        try {
          const response = await fetch(`https://immigraid-backend.immigraid.me/api/search-suggestions?prefix=${encodeURIComponent(searchInput)}&category=legal`);
          console.log('API Response:', response); // Log the response
          if (response.ok) {
            const data = await response.json();
            setSuggestions(data);
            setShowSuggestions(true);
          }
        } catch (error) {
          console.error('Error fetching suggestions:', error);
        }
      } else {
        setSuggestions([]);
        setShowSuggestions(false);
      }
    };

    const debounceTimer = setTimeout(() => {
      fetchSuggestions();
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [searchInput]);


  useEffect(() => {
    document.title = "Legal Assistance";

    fetch('https://immigraid-backend.immigraid.me/api/legal-services-data')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setLegalData(data);
          setFilteredLegalData(data); // Set initial filtered data
          setLoading(false);
        } else {
          console.error('Unexpected data format:', data);
          setError('Unexpected data format received.');
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching legal services data:", error);
        setError('Error fetching legal services data');
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      const fetchImageForService = async (service: LegalService) => {
        try {
          const response = await fetch(
            `https://api.unsplash.com/search/photos?query=${service.Name}&client_id=${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`
          );
          const data = await response.json();
          const imageUrl = data.results[0]?.urls?.regular || defaultImageUrl;
          setImages((prev) => ({ ...prev, [service.Name]: imageUrl }));
        } catch (error) {
          console.error(`Error fetching image for ${service.Name}:`, error);
          setImages((prev) => ({ ...prev, [service.Name]: defaultImageUrl }));
        }
      };

      legalData.forEach(fetchImageForService);
    };

    if (legalData.length > 0) {
      fetchImages();
    }
  }, [legalData]);

  const handleSearch = () => {
    let updatedLegalData = legalData;

    // Apply search filter
    if (searchInput) {
      updatedLegalData = updatedLegalData.filter(
        (service) =>
          service.Name.toLowerCase().includes(searchInput.toLowerCase()) ||
          service.description.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    // Apply state filter
    if (filterState) {
      updatedLegalData = updatedLegalData.filter(
        (service) => service.state.toLowerCase().includes(filterState.toLowerCase())
      );
    }

    // Apply city filter
    if (filterCity) {
      updatedLegalData = updatedLegalData.filter(
        (service) => service.city.toLowerCase().includes(filterCity.toLowerCase())
      );
    }

    // Apply phone filter
    if (filterPhone) {
      updatedLegalData = updatedLegalData.filter(
        (service) => service.phones.toLowerCase().includes(filterPhone.toLowerCase())
      );
    }

    if (isReversed) {
      updatedLegalData = updatedLegalData.sort((a, b) => {
        if (a.Name > b.Name) return -1;
        if (a.Name < b.Name) return 1;
        return 0;
      });
    }

    setFilteredLegalData(updatedLegalData);
    setIsSearchActive(true);

    // If no results match the filters, set an error message
    if (updatedLegalData.length === 0) {
      setError('No services match your search criteria.');
    } else {
      setError(null); // Clear error if results are found
    }

    setCurrentPage(1); // Reset to the first page
  };

  const clearFilters = () => {
    setSearchInput('');
    setFilterState('');
    setFilterCity('');
    setFilterPhone('');
    setIsReversed(false);
    setFilteredLegalData(legalData.sort((a, b) => {
      if (a.Name < b.Name) return -1;
      if (a.Name > b.Name) return 1;
      return 0;
    })); // Reset to all services
    setError(null); // Clear error message
    setIsSearchActive(false);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(filteredLegalData.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredLegalData.slice(indexOfFirstItem, indexOfLastItem);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const handleSuggestionClick = (suggestion: SearchSuggestion) => {
    setSearchInput(suggestion.text);
    setSearchQuery(suggestion.text);
    setShowSuggestions(false);
    setCurrentPage(1);
  };
  const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInput(e.target.value);
  };

  const goToPage = () => {
    const pageNumber = parseInt(pageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setPageInput('');
    } else {
      alert(`Please enter a valid page number between 1 and ${totalPages}`);
    }
  };

  if (loading) {
    return <p>Loading legal services data...</p>;
  }

  if (error && isSearchActive) {
    return <p>{error}</p>;
  }

  return (
    <div style={{ background: 'linear-gradient(to right, #001f3d, #4b7fb3)', color: 'white' }}>
    <div className="container">
      <h1>Legal Services Resources</h1>

      {/* Search and Filter Section with Suggestions */}
      <div ref={searchContainerRef} style={{ marginBottom: '20px', position: 'relative' }}>
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <div style={{ flex: 1, position: 'relative' }}>
            <input
              type="text"
              placeholder="Search services..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              style={{ padding: '8px', width: '100%' }}
            />
            {showSuggestions && suggestions.length > 0 && (
              <div style={{
                position: 'absolute',
                top: '100%',
                left: 0,
                right: 0,
                background: '#1a365d',
                border: '1px solid #2d4a77',
                borderRadius: '4px',
                boxShadow: '0 4px 6px rgba(0,0,0,0.3)',
                zIndex: 1000,
                marginTop: '4px',
              }}>
                {suggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                    style={{
                      padding: '8px 16px',
                      cursor: 'pointer',
                      borderBottom: index < suggestions.length - 1 ? '1px solid #2d4a77' : 'none',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#f0f0f0',
                      transition: 'all 0.2s ease',
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = '#2d4a77'; // Slightly lighter blue on hover
                      e.currentTarget.style.color = '#ffffff'; // Brighter white on hover
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = 'transparent';
                      e.currentTarget.style.color = '#f0f0f0';
                    }}
                  >
                    <span style={{ 
                      marginRight: '8px', 
                      color: suggestion.type === 'Name' ? '#60a5fa' : '#34d399' // Brighter colors for icons
                    }}>
                      {suggestion.type === 'Name' ? '🏥' : '📍'}
                    </span>
                    {suggestion.text}
                  </div>
                ))}
              </div>
            )}
          </div>

          <input
            type="text"
            placeholder="Filter by state..."
            value={filterState}
            onChange={(e) => setFilterState(e.target.value)}
            style={{ padding: '8px', width: '150px' }}
          />
          <input
            type="text"
            placeholder="Filter by city..."
            value={filterCity}
            onChange={(e) => setFilterCity(e.target.value)}
            style={{ padding: '8px', width: '150px' }}
          />
          <input
            type="text"
            placeholder="Filter by Phone..."
            value={filterPhone}
            onChange={(e) => setFilterPhone(e.target.value)}
            style={{ padding: '8px', width: '150px' }}
          />
          <label>
            <input
              name="reverse-alphabetical"
              type="checkbox"
              checked={isReversed}
              onChange={(e) => setIsReversed(e.target.checked)}
            />
            Reverse Alphabetical
          </label>
          <button onClick={handleSearch} style={{ padding: '8px 16px' }}>
            Search
          </button>
          <button onClick={clearFilters} style={{ padding: '8px 16px' }}>
            Clear Filters
          </button>
        </div>
      </div>

      {/* Items per page dropdown */}
      <div style={dropdownStyle}>
        <label htmlFor="itemsPerPage" style={{ marginRight: '10px' }}>Items per page:</label>
        <select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
        </select>
      </div>

      {/* Service Cards with highlighting */}
      <div className="row">
        {currentItems.map((service, index) => (
          <div key={index} className="col-md-4 mb-4">
            <Link 
              to={`/legalservices/${encodeURIComponent(service.Name)}`} 
              className="text-decoration-none"
            >
              <div className="card" style={{
                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out'
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'translateY(-5px)';
                e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'none';
                e.currentTarget.style.boxShadow = 'none';
              }}>
                <img
                  src={images[service.Name] || defaultImageUrl}
                  className="card-img-top"
                  alt={`Image of ${service.Name || 'Legal Service'}`}
                  style={{ height: '200px', objectFit: 'cover' }}
                />
                <div className="card-body">
                  <h5 className="card-title">
                    <Highlight text={service.Name || "Unknown Facility"} searchTerm={searchInput} />
                  </h5>
                  <p className="card-text">
                    Location: <Highlight 
                      text={`${service.city || "Unknown City"}, ${service.state || "Unknown State"}`}
                      searchTerm={filterState || filterCity || searchInput}
                    />
                  </p>
                  <p className="card-text">
                    {/* Phone: {service.phones || "N/A"} */}
                    Phone: <Highlight 
                      text={`${service.phones || "N/A"}, ${service.phones || "N/A"}`}
                      searchTerm={filterPhone || filterState || filterCity || searchInput}
                    />
                  </p>
                  <p className="card-text" style={{ 
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    marginBottom: '10px'
                  }}>
                    Description: <Highlight 
                      text={service.description || "No description available"} 
                      searchTerm={searchInput}
                    />
                  </p>
                  {service.link && (
                    <a 
                      href={service.link} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="btn btn-outline-primary btn-sm"
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        display: 'inline-block',
                        marginTop: 'auto'
                      }}
                    >
                      Visit Website
                    </a>
                  )}
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>

      {/* Pagination controls */}
      <div style={paginationStyle}>
        <button onClick={() => setCurrentPage(p => Math.max(p - 1, 1))} disabled={currentPage === 1}>
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={() => setCurrentPage(p => Math.min(p + 1, totalPages))} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>

      {/* Jump to page */}
      <div style={jumpToPageStyle}>
        <input
          type="number"
          value={pageInput}
          onChange={(e) => setPageInput(e.target.value)}
          placeholder={String(currentPage)}
          min="1"
          max={totalPages}
          style={pageInputStyle}
        />
        <button onClick={() => {
          const page = parseInt(pageInput);
          if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
            setPageInput('');
          }
        }} style={goButtonStyle}>
          Go
        </button>
      </div>
    </div>
    <footer className="text-center mt-5">
      <p style={{ color: 'white' }}>&copy; 2024 Our Site</p>
    </footer>
    </div>
      
  );
};

// Styles
const serviceCardStyle: React.CSSProperties = {
  border: '1px solid #ddd',
  padding: '16px',
  borderRadius: '8px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  flex: '0 1 30%',
  margin: '4px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '250px',
  height: '650px',
  overflow: 'hidden',
};

const cardContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '8px',
  maxWidth: '100%',
  padding: '16px',
  boxSizing: 'border-box',
  margin: '0 auto',
};

const dropdownStyle: React.CSSProperties = {
  marginBottom: '20px',
};

const paginationStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '20px',
};

const jumpToPageStyle: React.CSSProperties = {
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const pageInputStyle: React.CSSProperties = {
  marginRight: '10px',
  padding: '5px',
  width: '60px',
};

const goButtonStyle: React.CSSProperties = {
  padding: '5px 10px',
};

export default LegalServicesPage;
