import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

// Define the interface for a single form object
interface Form {
  form_details_url: string;
  form_id: number;
  form_name: string;
  form_number: string;
  form_type: string;
  form_url: string;
  usage_description: string;
  va_administration: string;
}

const FormsGraph: React.FC = () => {
  const [data, setData] = useState<{ type: string; count: number }[] | null>(null); // Store the chart data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state

  // Fetch the data when the component mounts
  useEffect(() => {
    fetch('https://api.vetassist.tech/forms') // Replace with your API endpoint
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((fetchedData) => {
        // Group the forms by form_type and count how many there are for each type
        const formTypeCount = fetchedData.reduce((acc: { [key: string]: number }, form: Form) => {
          const type = form.form_type;
          if (acc[type]) {
            acc[type]++;
          } else {
            acc[type] = 1;
          }
          return acc;
        }, {});

        // Convert the object to an array of objects for Recharts
        const chartData = Object.keys(formTypeCount).map(type => ({
          type,
          count: formTypeCount[type],
        }));

        setData(chartData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError(`Failed to load data: ${error.message}`);
        setLoading(false);
      });
  }, []);

  // Loading state
  if (loading) {
    return <p>Loading...</p>;
  }

  // Error state
  if (error) {
    return <p>{error}</p>;
  }

  // No data available state
  if (!data || data.length === 0) {
    return <p>No data available to display.</p>;
  }

  return (
    <div>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="type" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="count" fill="#1E90FF" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default FormsGraph;

