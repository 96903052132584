import React, { useState, useEffect } from 'react';
import { RadarChart, Radar, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Tooltip, ResponsiveContainer } from 'recharts';

interface Facility {
  address: string;
  facility_id: number;
  facility_name: string;
  facility_type: string;
  phone_number: string;
  website: string;
}

const FacilitiesBarChart: React.FC = () => {
  const [data, setData] = useState<{ type: string; count: number }[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetch('https://api.vetassist.tech/facilities')  // Replace with your actual API endpoint
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((fetchedData) => {
        // Process data to count the number of facilities by type
        const facilitiesByType = fetchedData.reduce((acc: { [key: string]: number }, facility: Facility) => {
          const type = facility.facility_type;
          if (acc[type]) {
            acc[type]++;
          } else {
            acc[type] = 1;
          }
          return acc;
        }, {});

        // Convert data into an array of objects for Recharts
        const chartData = Object.keys(facilitiesByType).map(type => ({
          type,
          count: facilitiesByType[type],
        }));

        setData(chartData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError('Failed to load data. Please try again later.');
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!data || data.length === 0) {
    return <p>No data available to display.</p>;
  }

  return (
    <div>
      <ResponsiveContainer width="100%" height={400}>
        <RadarChart outerRadius="80%" data={data}>
          <PolarGrid />
          <PolarAngleAxis dataKey="type" />
          <PolarRadiusAxis angle={30} domain={[0, Math.max(...data.map(d => d.count))]} />
          <Radar name="Facility Count" dataKey="count" stroke="#ff7300" fill="#ff7300" fillOpacity={0.6} />
          <Tooltip />
        </RadarChart>
      </ResponsiveContainer>
      </div>
  );
};

export default FacilitiesBarChart;

