import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Carousel, Container, Dropdown, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Import for navigation
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles.css'; // Link to the external CSS file
import splash1 from '../images/splash1.webp';
import splash2 from '../images/splash2.jpg';
import splash3 from '../images/splash3.jpg';
import educationLogo from '../icons/mortarboard.svg';
import healthcareLogo from '../icons/heart-pulse.svg';
import legalLogo from '../icons/search.svg';

const Home: React.FC = () => {
    const [language, setLanguage] = useState('english');
    const navigate = useNavigate(); // Hook for navigation

    useEffect(() => {
        document.title = "ImmigrAid"; 
    }, []);

    const renderContent = () => {
        switch (language) {
            case 'english':
                return (
                    <>
                        <h1 className="mt-5" style={{ color: 'white' }}>Welcome to our Website!</h1>
                        <p style={{ color: 'white' }}>
                            This website is dedicated to helping immigrants access essential resources in <strong>education</strong>, <strong>healthcare</strong>, and <strong>legal support</strong>. We strive to connect immigrants with programs, services, and information to help them thrive in their new community.
                        </p>
                    </>
                );
            case 'spanish':
                return (
                    <>
                        <h1 className="mt-5" style={{ color: 'white' }}>¡Bienvenido a nuestro sitio web!</h1>
                        <p style={{ color: 'white' }}>
                            Este sitio web está dedicado a ayudar a los inmigrantes a acceder a recursos esenciales en <strong>educación</strong>, <strong>atención médica</strong> y <strong>apoyo legal</strong>. Nos esforzamos por conectar a los inmigrantes con programas, servicios e información para ayudarlos a prosperar en su nueva comunidad.
                        </p>
                    </>
                );
            case 'chinese':
                return (
                    <>
                        <h1 className="mt-5" style={{ color: 'white' }}>欢迎来到我们的网站！</h1>
                        <p style={{ color: 'white' }}>
                            这个网站致力于帮助移民获得教育、医疗保健和法律支持等基本资源。我们努力将移民与专门帮助他们克服语言和文化障碍的项目、服务和信息联系起来，帮助他们在新的社区中茁壮成长。
                        </p>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div style={{ background: 'linear-gradient(to right, #001f3d, #4b7fb3)', minHeight: '100vh', color: 'white' }}>
            <Container>
                {/* Language Selector Dropdown */}
                <div className="language-dropdown">
                    <Dropdown>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                            Select Language
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setLanguage('english')}>English</Dropdown.Item>
                            <Dropdown.Item onClick={() => setLanguage('spanish')}>Español</Dropdown.Item>
                            <Dropdown.Item onClick={() => setLanguage('chinese')}>中文</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                {/* Render Content Based on Selected Language */}
                {renderContent()}

                

                {/* Carousel */}
                <Carousel className="mt-5">
                    <Carousel.Item>
                        <img src={splash1} className="d-block w-75 mx-auto" alt="Slideshow Image 1" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={splash2} className="d-block w-75 mx-auto" alt="Slideshow Image 2" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={splash3} className="d-block w-75 mx-auto" alt="Slideshow Image 3" />
                    </Carousel.Item>
                </Carousel>

                {/* Buttons with Logos */}
                <div className="resource-buttons mt-5 text-center">
                    <Button className="resource-btn mx-2" onClick={() => navigate('/EducationalPrograms')}>
                        <img src={educationLogo} alt="Education Logo" className="logo-img" />
                        Education
                    </Button>
                    <Button className="resource-btn mx-2" onClick={() => navigate('/healthcare')}>
                        <img src={healthcareLogo} alt="Healthcare Logo" className="logo-img" />
                        Healthcare
                    </Button>
                    <Button className="resource-btn mx-2" onClick={() => navigate('/legalservices')}>
                        <img src={legalLogo} alt="Legal Logo" className="logo-img" />
                        Legal Support
                    </Button>
                </div>
                {/* API Documentation Section */}
                <section className="mt-5 text-center">
                    <h2 style={{ color: 'white' }}>Our API Documentation</h2>
                    <p style={{ color: 'white' }}>Explore our API documentation to integrate ImmigriAid data into your applications. Click the button below to view more details.</p>
                    <a href="https://documenter.getpostman.com/view/38677006/2sAXxLBDsM" className="btn btn-primary" target="_blank" rel="noopener noreferrer">View API Documentation</a>
                </section>

                
            </Container>

            {/* Footer */}
            <footer className="text-center mt-5">
                <p style={{ color: 'white' }}>&copy; 2024 Our Site</p>
            </footer>
        </div>
    );
};

export default Home;